<template>
    <div class="barraIMM">
        <div class="logo-imm-menu">
            <span> INDESOL | Plataforma PAIMEF Transparente</span>

        </div>
    </div>
</template>

<script>
export default {
   
}
</script>